.App {
  /* text-align: center; */
}

.App-logo {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 10px;
}

:root {
  --color-borderColor: #d4d8e2;
  --color-text-primary: #000000;
}
