.tabMenu{
    cursor: pointer;
    color: #200E32;
    font-weight: 500;
    @media (max-width:1021px) {
    /* font-weight: 400; */
    font-size: 15px;
    }
    /* border-bottom:2px solid #3A57E8 ; */
    /* border-bottom: ${(props:TabMenuElement) => props.isSelectdItem ? '2px solid #3A57E8':''};  */
    /* display:${(props:TabMenuElement) => props.isSelectdItem ? 'inline-flex':'none'}; */ 
/* color: ${(props:TabMenuElement) => props.isSelectdItem ? '#3A57E8':'#200E32'};  */
}
.tabMenuSelect{
    cursor: pointer;
    font-weight: 700;
    /* color: #3A57E8; */
    color: #003459;
    /* border-bottom: 3px solid #3A57E8; */
    border-bottom: 3px solid #003459;
    background-color: rgba(58, 87, 232, 0.12);
    @media (max-width:1021px) {
        /* font-weight: 600; */
        font-size: 15px;
        }
}
