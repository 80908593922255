.carousel {

    display: flex;
    justify-content: center;
}

.carousel .slide {
    height: auto;
}

.carousel .slide img {
    max-height: 70%;
    width: 70%;
    background: #f0f0f0;

}

.image {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
    background-color: white;

}

.carousel .thumb img {
    height: 40px;
    width: 40px;
}

.carousel .thumb:focus {
    border: 2px solid gray;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 2px solid gray;

}