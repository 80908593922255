.carousel {
    width: 600px;
    display: flex;
    justify-content: center;

    @media (max-width:650px) {
        width: 370px;

    }
}

.carousel .slide {
    height: auto;
}

.carousel .slide img {
    max-height: 70%;
    width: 70%;
    background: #f0f0f0;

}

.image {
    /* margin: 0 auto;
    height: auto;
    width: 50%; */
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
    background-color: white;
}

.carousel .thumbs-wrapper {
    overflow: hidden;
    /* border: 1px solid black; */
    margin: 0;
    width: 100%;
}

.carousel .thumbs {
    /* border: 1px solid black; */
    margin: 5px;
    width: 100%;

}


.carousel .thumb {
    padding: 0px;
    /* border: 1px solid black; */
    margin-right: 0px;
}



.carousel .thumb img {
    display: block;
    height: 40px;
    /* width: 40px; */
    object-fit: contain;

}

/* .carousel .thumb:focus{
    border: 2px solid gray;
}
.carousel .thumb.selected, .carousel .thumb:hover{
    border: 2px solid gray;
    
} */