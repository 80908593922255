@keyframes ldio-eyk1pubtabv {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  .ldio-eyk1pubtabv div { box-sizing: border-box!important }
  .ldio-eyk1pubtabv > div {
    position: absolute;
    width: 173.81px;
    height: 173.81px;
    top: 8.594999999999999px;
    left: 8.594999999999999px;
    border-radius: 50%;
    border: 17.189999999999998px solid #000;
    border-color: #003459 transparent #003459 transparent;
    animation: ldio-eyk1pubtabv 1s linear infinite;
  }
  
  .ldio-eyk1pubtabv > div:nth-child(2), .ldio-eyk1pubtabv > div:nth-child(4) {
    width: 135.60999999999999px;
    height: 135.60999999999999px;
    top: 27.695px;
    left: 27.695px;
    animation: ldio-eyk1pubtabv 1s linear infinite reverse;
  }
  .ldio-eyk1pubtabv > div:nth-child(2) {
    border-color: transparent #22577c transparent #22577c
  }
  .ldio-eyk1pubtabv > div:nth-child(3) { border-color: transparent }
  .ldio-eyk1pubtabv > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-eyk1pubtabv > div:nth-child(3) div:before, .ldio-eyk1pubtabv > div:nth-child(3) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 17.189999999999998px;
    height: 17.189999999999998px;
    top: -17.189999999999998px;
    left: 61.12px;
    background: #003459;
    border-radius: 50%;
    box-shadow: 0 156.62px 0 0 #003459;
  }
  .ldio-eyk1pubtabv > div:nth-child(3) div:after {
    left: -17.189999999999998px;
    top: 61.12px;
    box-shadow: 156.62px 0 0 0 #003459;
  }
  
  .ldio-eyk1pubtabv > div:nth-child(4) { border-color: transparent; }
  .ldio-eyk1pubtabv > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-eyk1pubtabv > div:nth-child(4) div:before, .ldio-eyk1pubtabv > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 17.189999999999998px;
    height: 17.189999999999998px;
    top: -17.189999999999998px;
    left: 42.019999999999996px;
    background: #22577c;
    border-radius: 50%;
    box-shadow: 0 118.42px 0 0 #22577c;
  }
  .ldio-eyk1pubtabv > div:nth-child(4) div:after {
    left: -17.189999999999998px;
    top: 42.019999999999996px;
    box-shadow: 118.42px 0 0 0 #22577c;
  }
  .loadingio-spinner-double-ring-fwxl3phwb08 {
    width: 191px;
    height: 191px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-eyk1pubtabv {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-eyk1pubtabv div { box-sizing: content-box; }